import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { SwaggerException } from "@/shared/service-proxies/model";
export class AdvertisingConfigServiceProxy {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver:
        | ((key: string, value: any) => any)
        | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl ? baseUrl : "";
    }
    //分页获取信息
    async getPaged(
        filterText: string | undefined,
        sorting: string | undefined,
        maxResultCount: number | undefined,
        skipCount: number | undefined
    ): Promise<any> {
        let url_ = this.baseUrl + `/api/services/app/AdvertisingConfig/GetPaged?`;
        if (filterText === null) {
            throw new Error("The parameter 'filterText' cannot be null.");
        } else if (filterText !== undefined) {
            url_ += "filterText=" + encodeURIComponent("" + filterText) + "&";
        }
        if (sorting === null) {
            throw new Error("The parameter 'sorting' cannot be null.");
        } else if (sorting !== undefined) {
            url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
        }
        if (maxResultCount === null) {
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        } else if (maxResultCount !== undefined) {
            url_ += "maxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        }
        if (skipCount === null) {
            throw new Error("The parameter 'skipCount' cannot be null.");
        } else if (skipCount !== undefined) {
            url_ += "skipCount=" + encodeURIComponent("" + skipCount) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetPaged(_response);
    }

    protected processGetPaged(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            // result200 = PagedResultDtoOfCompanyListDto.fromJS(resultData200);
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }
    async getEditById(id: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/AdvertisingConfig/GetEditById?id=" + id;
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetForEdit(_response);
    }
    protected processGetForEdit(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }
    async createOrUpdate(body: any | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/AdvertisingConfig/SaveAdvertisingConfig";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);
        console.log(content_);
        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        };
        var res = await this.instance.request(options_);
        var fnc = await this.processCreateOrUpdate(res);

        return fnc;
    }
    protected processCreateOrUpdate(response: AxiosResponse): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<any>(<any>response);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>response);
    }
}

function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
): any {
    console.log(result);
    if (result !== null && result !== undefined) {
        throw result;
    } else {
        throw new SwaggerException(message, status, response, headers, null);
    }
}
