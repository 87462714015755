<template>
  <a-spin :spinning="spinning">
    <!-- <page-header title="意见反馈列表"></page-header> -->
      <!-- <a-row> -->
      <!-- table -->
      <!-- <react-table 
        :table-data="tableData"
        :total-items="totalItems"
        :tableKey="tableKey"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :current-page="pageNumber"
        @emitRefreshData="getData"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        :table-data="tableData"
        :total-items="totalItems"
        :tableKey="tableKey"
        :is-full="true"
        :actions-type="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
         @emitRefreshData="getData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <!-- 按钮 -->
        <a-row :gutter="4" class="">
          <a-col class="gutter-row" :span="8">
            <a-button type="primary" @click="createOrEdit(null, 'create')" v-if="isGranted('Pages.LGModul.Advertising.Create')">
              <a-icon type="plus" />
              {{ l("Create") }}
            </a-button>
          </a-col>
          <a-col class="gutter-row" :span="6"> </a-col>
          <a-col class="gutter-row" :span="6">
            <a-input
              v-model.trim="filterText"
              :placeholder="l('请输入搜索内容')"
              allow-clear
            />
          </a-col>
          <a-col class="gutter-row" style="float: right" :span="4">
            <a-button type="primary" @click="search" v-if="isGranted('Pages.LGModul.Advertising.Query')">
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          </a-col>
        </a-row>
      </ele-table>
      <!-- </a-row> -->
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import { AdvertisingConfigServiceProxy } from "./services/advertisingconfig-proxies";
import moment from "moment";
import CreateEdit from "./create-edit/create-edit";
import { AppConsts } from "@/abpPro/AppConsts";
import EleTable from "@/components/ele-table";

export default {
  mixins: [AppComponentBase],
  name: "feedback-index",
  components: { EleTable },
  data() {
    return {
      spinning: false,
      selectedRowKeys: [],
      tableData: [],
      tableKey: "AdvertisingConfigPagedDto",
      // 搜索
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { maxResultCount: 50, skipCount: 0 },
      _serviceProxy: "",
      commonService: null,
      actionsType: {},
    };
  },
  created() {
    this._serviceProxy = new AdvertisingConfigServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.getData();
    this.initActionsType();
  },
  methods: {
    search() {
      this.pageNumber = 1
      this.request.skipCount = 0
      this.getData()
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      try {
        this._serviceProxy
          .getPaged(
            this.filterText,
            "",
            this.request.maxResultCount,
            this.request.skipCount
          )
          .finally(() => {
            this.spinning = false;
          })
          .then((result) => {
            this.tableData = result.items.map((o) => {
              o.status = ["禁用", "有效"][o.status];
              o.images = AppConsts.uploadBaseUrl + o.images;
              return {
                ...o,
              };
            });
            this.totalItems = result.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.request.maxResultCount + 1,
              this.pageNumber * this.request.maxResultCount,
            ];
            this.totalPages = Math.ceil(
              result.totalCount / this.request.maxResultCount
            );
          });
      } catch (error) {
        this.spinning = false;
      }
    },
    // 预览
    previewItem(newV) {
      console.log(newV);
      this.createOrEdit(newV.item, "preview");
    },
    /**
     * emit新建修改
     */
    editItem(newV) {
      // let { id } = newV.item;
      this.createOrEdit(newV.item, "edit");
    },
    /**
     * 单个删除
     */
    deleteItem(item) {
      this.spinning = true;
      this._serviceProxy
        .deleteCustomer(item.item.id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    /**
     * 新建修改
     */
    createOrEdit(item, type) {
      ModalHelper.create(
        CreateEdit,
        {
          query: {
            id: item ? item.id : "",
            type: type,
          },
        },
        {
          width: "800px",
          isChange: true,
        }
      ).subscribe((res) => {
        console.log(res);
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * 排序更新data
     */
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 分页
     */
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      console.log(newV);
      let { curPage, maxResultCount } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = maxResultCount;
      this.getData();
    },
    /**
     * table选择事件
     */
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    /**
     * 初始化需要显示的func btn
     */
    initActionsType() {
      let _this = this;
      let obj = {
        type: "",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.LGModul.Suggest.Detail"),
              name: "查看",
              icon: "profile",
              fn: (data) => {
                _this.createOrEdit(data, "preview");
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Advertising.Edit"),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
          ],
          delete: {
            granted: this.isGranted("Pages.LGModul.Advertising.Delete"),
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data);
            },
          },
        },
      };
      this.actionsType = obj;
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}
.btn--footer {
  // border: 1px solid #e8e8e8;
  // margin: 20px;
  // padding: 20px;
  .table-delete {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.btn--footer {
  margin-bottom: 20px;
}
</style>
